<footer class="container pt-40 pb-20 px-20">
    <div class="flex items-center justify-between max-w-500 mx-auto">
        <h5>La Boussole, un problème ?</h5>

        <button type="button" uiButton size="l" class="min-w-160 ml-20">Nous contacter</button>
    </div>

    <nav aria-label="Footer">
        <ul class="flex flex-col lg:flex-wrap lg:h-110 mt-40 mx-auto lg:pl-200">
            <li>
                <a routerLink="/">Mentions légales</a>
            </li>
            <li>
                <a routerLink="/">Politique de confidentialité</a>
            </li>
            <li>
                <a routerLink="/">Conditions générales d'utilisation</a>
            </li>
            <li>
                <a routerLink="/">Configuration des cookies</a>
            </li>
            <li>
                <a routerLink="/">Plan du site</a>
            </li>
            <li>
                <a routerLink="/">Accessibilité</a>
            </li>
            @if (canSignin()) {
                <li>
                    <a routerLink="/inscription">J'inscris mon entreprise/mon organisation</a>
                </li>
            }
        </ul>
    </nav>

    <div class="w-full h-2 bg-primary/20 my-40"></div>

    <div class="flex justify-between">
        © 2023 Mission Locale Lorient

        <ul class="flex items-center">
            <li>
                <a href="#" class="flex items-center py-3 mr-7.5">
                    <i class="iconoir-instagram text-20"></i>
                </a>
            </li>
            <li>
                <a href="#" class="flex items-center py-3">
                    <i class="iconoir-linkedin text-20"></i>
                </a>
            </li>
        </ul>
    </div>
</footer>
import { Component, computed, inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonDirective } from '@lorient-emploi-front/shared/ui/directives';
import { RouterModule } from '@angular/router';
import { SiteSettingsService } from '@lorient-emploi-front/shared/data-access';

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [CommonModule, ButtonDirective, RouterModule],
  templateUrl: './footer.component.html'
})
export class FooterComponent implements OnInit {
  private _siteSettingsService = inject(SiteSettingsService);

  protected canSignin = computed(() => this._siteSettingsService.canSignin().data );

  ngOnInit(): void {
    this._siteSettingsService.getShowUserCreation();
  }
}

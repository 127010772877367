import { APP_INITIALIZER, ApplicationConfig, importProvidersFrom } from '@angular/core';
import { provideRouter, withComponentInputBinding } from '@angular/router';
import { appRoutes } from './app.routes';
import { provideClientHydration } from '@angular/platform-browser';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { API_URL } from '@lorient-emploi-front/core/http-client';
import { environment } from 'src/environments/environment';
import { provideHttpClient, withFetch, withInterceptors } from '@angular/common/http';
import { errorHandlingInterceptor } from '@lorient-emploi-front/core/error-handler';
import { GeoApiGouvAddressModule } from '@placeme/ngx-geo-api-gouv-address';
import { SeoService } from '@lorient-emploi-front/shared/data-access';

export function initializeSeoStatisticFactory(seoService: SeoService): () => void {
  return () => seoService.initializeSeoStatistic();
} 

export const appConfig: ApplicationConfig = {
  providers: [
    provideClientHydration(),
    provideRouter(appRoutes, withComponentInputBinding()),
    provideAnimationsAsync(),
    provideHttpClient(
      withFetch(),
      withInterceptors([errorHandlingInterceptor])
    ),
    importProvidersFrom(GeoApiGouvAddressModule.forRoot()),
    { provide: API_URL, useValue: environment.API_URL },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeSeoStatisticFactory,
      multi: true,
      deps: [SeoService]
    }
  ],
};

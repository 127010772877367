import { CommonModule } from '@angular/common';
import { Component, OnInit, inject, signal } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router, RouterModule } from '@angular/router';
import { HeaderComponent } from './layout/header/header.component';
import { FooterComponent } from './layout/footer/footer.component';
import { BreadcrumbComponent } from '@lorient-emploi-front/shared/ui/components';
import { ToastComponent } from '@lorient-emploi-front/core/error-handler';
import { filter } from 'rxjs';

@Component({
  standalone: true,
  imports: [CommonModule, RouterModule, HeaderComponent, FooterComponent, BreadcrumbComponent, ToastComponent],
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  meta = inject(Meta);
  title = inject(Title);
  hideFooter = signal<boolean>(false);

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        const children: ActivatedRoute[] = this.activatedRoute.root.children;

        for (const child of children) {
          if (child.snapshot.data['hideFooter']) {
            this.hideFooter.set(true);
          } else {
            this.hideFooter.set(false);
          }
        }
      });
  }

  ngOnInit() {
    this.title.setTitle('La boussole');
    this.meta.updateTag({ name: 'description', content: 'La boussole est un outil de recherche d\'emploi à Lorient' });
  }
}

<header class="fixed z-[1500] top-0 lg:relative w-full border-b-2 border-primary-medium bg-white">
    <div class="container flex items-center justify-between py-18 px-20 lg:py-20">
        <a routerLink="/" class="flex flex-col">
            <h4>La boussole</h4>
            <span class="text-12">par la Mission Locale Réseaux pour l'emploi du Pays de Lorient</span>
        </a>

        <button class="cursor-pointer text-24 text-primary leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none" type="button" (click)="openMenu()">
            <i class="iconoir-menu"></i>
        </button>
    
        <nav class="hidden lg:block" aria-label="Main">
            <ul class="flex items-center">
                <li class="mr-10">
                    <a class="p-15" routerLink="/">Bienvenue</a>
                </li>
                <li class="mr-10">
                    <a class="p-15" routerLink="/recherche">Rechercher</a>
                </li>
                <li class="mr-10">
                    <a class="p-15" routerLink="/carte">Carte</a>
                </li>
                <li class="mr-10">
                    <a class="p-15" routerLink="/decouvrir">Découvrir</a>
                </li>
                <li>
                    <a class="p-15" routerLink="/agenda">Agenda</a>
                </li>
            </ul>
        </nav>
    </div>
</header>

<ng-template #mobileMenu>
    <div class="w-full h-dvh !bg-primary-shades overflow-y-auto">
        <div class="container flex flex-col h-inherit pt-20 px-20">
            <button type="button" uiButton (click)="closeMenu()" class="leading-0 self-end">
                <i class="iconoir-xmark text-24"></i>
            </button>
    
            <h3 class="pt-86 pb-15 sm:pt-0 sm:pb-60 text-32">Menu</h3>
    
            <nav class="flex-1 sm:flex-auto flex items-center">
                <ul class="flex flex-col w-full">
                    <li>
                        <a class="p-15 flex items-center" routerLink="/">
                            <i class="iconoir-home-alt text-20 mr-15"></i>
                            Bienvenue
                        </a>
                    </li>
                    <span class="w-full h-1 bg-primary/50 my-15"></span>
                    <li>
                        <a class="p-15 flex items-center" routerLink="/recherche">
                            <i class="iconoir-doc-magnifying-glass-in text-20 mr-15"></i>
                            Recherche
                        </a>
                    </li>
                    <span class="w-full h-1 bg-primary/50 my-15"></span>
                    <li>
                        <a class="p-15 flex items-center" routerLink="/carte">
                            <i class="iconoir-map-pin text-20 mr-15"></i>
                            Carte
                        </a>
                    </li>
                    <span class="w-full h-1 bg-primary/50 my-15"></span>
                    <li>
                        <a class="p-15 flex items-center" routerLink="/decouvrir">
                            <i class="iconoir-globe text-20 mr-15"></i>
                            Découvrir
                        </a>
                    </li>
                    <span class="w-full h-1 bg-primary/50 my-15"></span>
                    <li>
                        <a class="p-15 flex items-center" routerLink="/agenda">
                            <i class="iconoir-calendar text-20 mr-15"></i>
                            Agenda
                        </a>
                    </li>
                </ul>
            </nav>
            
            <div class="flex py-50">
                <a routerLink="/recherche" uiButton size="full" class="self-center text-center">
                    Rechercher
                </a>
            </div>
        </div>
    </div>
</ng-template>
<footer class="bg-white pt-72 px-24 pb-56 ">
    <div class="md:max-w-1100 md:flex justify-between m-auto">
        <div class="md:max-w-130">
            <img class="mb-24" src="assets/images/logo.svg" alt="MonFuturPro">
        
            <p class="text-gray-300 text-16 mb-48">
                Votre guide numérique vers la découverte du nouveau pays de Lorient, connectant métiers, entreprises et opportunités en un swipe !
            </p>
        </div>
    
        @for (plan of sitePlans; track $index) {
            <div class="mb-48">
                <p class="mb-24 font-semibold text-14 text-black">{{ plan.name }}</p>
    
                @for (link of plan.links; track $index) {
                    <a class="px-8 py-12 block font-semibold text-14 text-gray-300" [routerLink]="link.href">{{ link.name }}</a>
                }
            </div>
    
        }
    </div>

    <div class="m-auto md:max-w-1100 w-full border-b-1 border-gray-200 mb-32"></div>

    <div class="m-auto md:max-w-1100 flex justify-between">
        <div class="">
            <p class="text-gray-300">© 2024 MISSION</p>
            <p class="text-gray-300">LOCALE LORIENT</p>
        </div>

        <div class="flex gap-16">
            <img class="w-48" src="assets/images/facebook.svg" alt="facebook">
            <img class="w-48" src="assets/images/instagram.svg" alt="instagram">
            <img class="w-48" src="assets/images/linkedin.svg" alt="linkedin">
        </div>
    </div>
</footer>

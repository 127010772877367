import { Component, OnInit, OnDestroy, TemplateRef, ViewContainerRef, inject, viewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonDirective } from '@lorient-emploi-front/shared/ui/directives';
import { NavigationStart, Router, RouterModule } from '@angular/router';
import { Overlay, OverlayModule } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { Subscription, filter } from 'rxjs';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [CommonModule, ButtonDirective, RouterModule, OverlayModule],
  templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit, OnDestroy {
  overlay = inject(Overlay);
  viewContainerRef = inject(ViewContainerRef);
  private _router = inject(Router);
  mobileMenu = viewChild<TemplateRef<Element>>('mobileMenu');
  showMenu = false;
  
  private _overlayRef = this.overlay.create({
    hasBackdrop: true,
    width: '100vw',
    height: '100vh',
    scrollStrategy: this.overlay.scrollStrategies.block()
  });

  private _routerSubscription!: Subscription;

  ngOnInit() {
    this._routerSubscription = this._router.events
    .pipe(
      filter(event => event instanceof NavigationStart),
      filter(() => !!this._overlayRef)
    )
    .subscribe(() => {
      this.closeMenu();
    });
  }

  openMenu() {
    const mobileMenu = this.mobileMenu();

    if (mobileMenu) {
      const portal = new TemplatePortal(mobileMenu, this.viewContainerRef);
      this._overlayRef.attach(portal);
      this._overlayRef.backdropClick().subscribe(() => this.closeMenu());
    }
  }

  closeMenu() {
    if (this._overlayRef && this._overlayRef.hasAttached()) {
      this._overlayRef.detach();
    }
  }

  ngOnDestroy(): void {
    if(this._routerSubscription) {
      this._routerSubscription.unsubscribe();
    }
  }
}

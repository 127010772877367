<div class="h-88">
    <app-header></app-header>
</div>
<div class="h-full max-h-[calc(100%-88px)] lg:h-auto lg:max-h-none">
    <section class="container px-20">
        <ui-breadcrumb></ui-breadcrumb>
    </section>
    <router-outlet></router-outlet>
    <section [ngClass]="{'hidden lg:block': hideFooter()}">
        <app-footer></app-footer>
    </section>
</div>

<error-toast></error-toast>